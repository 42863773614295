import React, { useEffect, useReducer } from "react";
import CardDownload from "../../Components/CardDownload";
import SecondaryBanner from "../../Components/SecondaryBanner";

const BaselDisclosure = () => {
  useEffect(() => {
    document.title = "Basel Disclosure";
    window.scrollTo(0, 0);
  }, []);

  const bannerData = {
    breadcrumb: ["Home", "Investor Relationship", "Basel Disclosure"],
    bannerTitle: "Basel Disclosure",
  };

  const initialState = {
    selectedYear: "all",
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "changeYear":
        return { ...state, selectedYear: action.year };

      default:
        throw new Error("Action type not defined");
    }
  };

  const cardData = [
    {
      id: 12,
      title: "Basel Disclosure 4th Quarter(Ashad 2081), 2080/81",
      link: "https://gurkhasfinance.com.np/assets/Basel-Disclosure/Basel Report Ashad 2081.pdf",
      year: "2080/81",
    },
    {
      id: 11,
      title: "Basel Disclosure 3rd Quarter(Chaitra 2080), 2080/81",
      link: "https://gurkhasfinance.com.np/assets/Basel-Disclosure/Basel Report Chaitra 2080.pdf",
      year: "2080/81",
    },
    {
      id: 10,
      title: "Basel Disclosure 2nd Quarter, 2080/81",
      link: "https://gurkhasfinance.com.np/assets/Basel-Disclosure/Basel Disclosure 2nd Qtr. FY 2080-81.pdf",
      year: "2080/81",
    },
    {
      id: 9,
      title: "Basel Disclosure 1st Quarter, 2080/81",
      link: "https://gurkhasfinance.com.np/assets/Basel-Disclosure/Basel Disclosure 1st Qtr. FY 2080-81.pdf",
      year: "2080/81",
    },
    {
      id: 8,
      title: "Basel Disclosure 4th Quarter, 2079/80",
      link: "https://gurkhasfinance.com.np/assets/Basel-Disclosure/Basel Disclosure 4th Qtr. FY 2079-80.pdf",
      year: "2079/80",
    },
    {
      id: 7,
      title: "Basel Disclosure 3rd Quarter, 2079/80",
      link: "https://gurkhasfinance.com.np/assets/Basel-Disclosure/Basel Disclosure 3rd Qtr. FY 2079-80.pdf",
      year: "2079/80",
    },
    {
      id: 6,
      title: "Basel Disclosure 2nd Quarter, 2079/80",
      link: "https://gurkhasfinance.com.np/assets/Basel-Disclosure/Basel Disclosure 2nd Qtr. FY 2079-80.pdf",
      year: "2079/80",
    },
    {
      id: 5,
      title: "Basel Disclosure 1st Quarter, 2079/80",
      link: "https://gurkhasfinance.com.np/assets/Basel-Disclosure/Basel Disclosure 1st Qtr. FY 2079-80.pdf",
      year: "2079/80",
    },
    {
      id: 4,
      title: "Basel Disclosure 4th Quarter, 2078/79",
      link: "https://gurkhasfinance.com.np/assets/Basel-Disclosure/2078-79-Q4.pdf",
      year: "2078/79",
    },
    {
      id: 3,
      title: "Basel Disclosure 3rd Quarter, 2078/79",
      link: "https://gurkhasfinance.com.np/assets/Basel-Disclosure/2078-79-Q3.pdf",
      year: "2078/79",
    },
    {
      id: 2,
      title: "Basel Disclosure 2nd Quarter, 2078/79",
      link: "https://gurkhasfinance.com.np/assets/Basel Disclosure Q2 FY 2078-79.pdf",
      year: "2078/79",
    },

    {
      id: 1,
      title: "Basel Disclosure 1st Quarter, 2078/79",
      link: "https://gurkhasfinance.com.np/assets/Basel Disclosure Q1 FY 2078-79.pdf",
      year: "2078/79",
    },
  ];

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section class="section-sub-header">
        <div class="container">
          <div class="content">
            <div class="location-dropdown">
              <select
                id="year-dropdown"
                onChange={(e) =>
                  dispatch({ type: "changeYear", year: e.target.value })
                }
              >
                <option value="all">All Fiscal Year</option>
                <option value="2080/81">2080/81</option>
                <option value="2079/80">2079/80</option>
                <option value="2078/79">2078/79</option>
                <option value="2077/78">2077/78</option>
                {/* <option value="2076/77">2076/77</option>
                <option value="2075/76">2075/76</option>
                <option value="2074/75">2074/75</option>
                <option value="2073/74">2073/74</option> */}
              </select>
            </div>
          </div>
        </div>
      </section>
      <section className="section-report">
        <div className="container">
          <div className="card-row">
            {state.selectedYear === "all"
              ? cardData.map((card) => (
                  <CardDownload
                    cardTitle={card.title}
                    link={card.link}
                    key={card.id}
                  />
                ))
              : cardData.map(
                  (card) =>
                    card.year === state.selectedYear && (
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    )
                )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default BaselDisclosure;
