import React, { useReducer, useEffect } from "react";
import CardDownload from "../../Components/CardDownload";
import CardReport from "../../Components/CardReport";
import Contact from "../../Components/Contact";
import SecondaryBanner from "../../Components/SecondaryBanner";

const RightToInformation = () => {
  useEffect(() => {
    document.title = "Right To Information";
    window.scrollTo(0, 0);
  }, []);
  const bannerData = {
    breadcrumb: ["Home", "Investor Relationship", "Right To Information"],
    bannerTitle: "Right To Information",
  };
  const initialState = {
    selectedYear: "all",
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "changeYear":
        return { ...state, selectedYear: action.year };
      default:
        throw new Error("Action type not defined");
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const cardData = [
    {
      id: 31,
      title: "Right to Information - Ashad 2081 (4th Quarter for F.Y. 080-81)",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 080-81/Right to Information Ashad 2081.pdf",
      year: "2080/81",
    },
     {
      id: 30,
      title: "Right to Information - Chaitra 2080 (3rd Quarter for F.Y. 080-81)",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 080-81/Right to Information Chaitra 2080.pdf",
      year: "2080/81",
    },
    {
      id: 29,
      title: "Right to Information - Poush 2080 (2nd Quarter for F.Y. 080-81)",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 080-81/Right to Information-2nd Qtr. F.Y. 080-81.pdf",
      year: "2080/81",
    },
     {
      id: 28,
      title: "Right to Information - Ashoj 2080 (1st Quarter for F.Y. 080-81)",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 080-81/Right to Information-1st Qtr. F.Y. 080-81.pdf",
      year: "2080/81",
    },
    {
      id: 27,
      title: "Right to Information -4th Qtr 2079/80",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 079-80/Right to Information-4th Qtr. F.Y. 079-80.pdf",
      year: "2079/80",
    },
    {
      id: 26,
      title: "Right to Information -3rd Qtr 2079/80",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 079-80/Right to Information (RTI) - Chaitra 2079.pdf",
      year: "2079/80",
    },
    {
      id: 25,
      title: "Right to Information -2nd Qtr 2079/80",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 079-80/RTI-2nd-79-80.pdf",
      year: "2079/80",
    },
    {
      id: 24,
      title: "Right to Information -1st Qtr 2079/80",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 079-80/Right to Information-1st Qtr. F.Y. 079-80.pdf",
      year: "2079/80",
    },
    {
      id: 23,
      title: "Right to Information -4th Qtr 2078/79",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 078-79/Right to Information-4th Qtr. F.Y. 078-79.pdf",
      year: "2078/79",
    },
    {
      id: 22,
      title: "Right to Information -3rd Qtr 2078/79",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 078-79/Right to Information-3rd Qtr. F.Y. 078-79.pdf",
      year: "2078/79",
    },
    {
      id: 21,
      title: "Right to Information -2nd Qtr 2078/79",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 078-79/Right to Information-2nd Qtr. F.Y. 078-79.pdf",
      year: "2078/79",
    },
    {
      id: 20,
      title: "Right to Information -1st Qtr 2078/79",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 078-79/Right to Information-1st Qtr. F.Y. 078-79.pdf",
      year: "2078/79",
    },
    {
      id: 19,
      title: "Right to Information -4th Qtr 2077/78",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 077-78/Right to Information-4th Qtr. F.Y. 077-78.pdf",
      year: "2077/78",
    },
    {
      id: 18,
      title: "Right to Information -3rd Qtr 2077/78",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 077-78/Right to Information-3rd Qtr. F.Y. 077-78.pdf",
      year: "2077/78",
    },
    {
      id: 17,
      title: "Right to Information -2nd Qtr 2077/78",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 077-78/Right to information-2nd Qtr. F.Y. 077-78.pdf",
      year: "2077/78",
    },
    {
      id: 16,
      title: "Right to Information -1st Qtr 2077/78",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 077-78/Right to information-1st Qtr. F.Y. 077-78.pdf",
      year: "2077/78",
    },
    {
      id: 15,
      title: "Right to Information -4th Qtr 2076/77",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 076-77/Right to Information-4th Qtr. 076-77.pdf",
      year: "2076/77",
    },
    {
      id: 13,
      title: "Right to Information -3rd Qtr 2076/77",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 076-77/Right to Information -3rd Qtr. 076-77.pdf",
      year: "2076/77",
    },
    {
      id: 14,
      title: "Right to Information -1st Qtr 2076/77",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 076-77/Right to Information-1st Qtr. 076-77.pdf",
      year: "2076/77",
    },

    {
      id: 12,
      title: "Right to Information -4th Qtr 2075/76",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 075-76/Right to Information-4th Qtr. 075-76.pdf",
      year: "2075/76",
    },
    {
      id: 11,
      title: "Right to Information -3rd Qtr 2075/76",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 075-76/Right to Information-3rd Qtr. 075-76.pdf",
      year: "2075/76",
    },
    {
      id: 10,
      title: "Right to Information -2nd Qtr 2075/76",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 075-76/Right to Information-2nd Qtr. 075-76.pdf",
      year: "2075/76",
    },
    {
      id: 9,
      title: "Right to Information -1st Qtr 2075/76",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 075-76/Right to Information-1st Qtr. 075-76.pdf",
      year: "2075/76",
    },
    {
      id: 8,
      title: "Right to Information -4th Qtr 2074/75",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 074-75/Right to Information-4th Qtr. 074-75.docx.pdf",
      year: "2074/75",
    },
    {
      id: 7,
      title: "Right to Information -3rd Qtr 2074/75",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 074-75/Right to Information-3rd Qtr. 074-75.pdf",
      year: "2074/75",
    },
    {
      id: 6,
      title: "Right to Information -2nd Qtr 2074/75",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 074-75/Right to Information-2nd Qtr. 074-75.pdf",
      year: "2074/75",
    },
    {
      id: 5,
      title: "Right to Information -1st Qtr 2074/75",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 074-75/Right to Information-1st Qtr. 074-75.pdf",
      year: "2074/75",
    },
    {
      id: 4,
      title: "Right to Information -3rd Qtr 2073/74",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 073-74/Right to Information-3rd Qtr. 073-74.docx.pdf",
      year: "2073/74",
    },
    {
      id: 3,
      title: "Right to Information -2nd Qtr 2073/74",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 073-74/Right to Information-2nd Qtr. 073-74.pdf",
      year: "2073/74",
    },
    {
      id: 2,
      title: "Right to Information -1st Qtr 2073/74",
      link: "https://gurkhasfinance.com.np/assets/right of information/F.Y. 073-74/Right to Information-1st Qtr. 073-74.pdf",
      year: "2073/74",
    },
  ];
  return (
    <main>
      <SecondaryBanner bannerData={bannerData} />
      <section class="section-sub-header">
        <div class="container">
          <div class="content">
            <div class="location-dropdown">
              <select
                id="year-dropdown"
                onChange={(e) =>
                  dispatch({ type: "changeYear", year: e.target.value })
                }
              >
                <option value="all">All Fiscal Year</option>
                <option value="2080/81">2080/81</option>
                <option value="2079/80">2079/80</option>
                <option value="2078/79">2078/79</option>
                <option value="2077/78">2077/78</option>
                <option value="2076/77">2076/77</option>
                <option value="2075/76">2075/76</option>
                <option value="2074/75">2074/75</option>
                <option value="2073/74">2073/74</option>
              </select>
            </div>
          </div>
        </div>
      </section>
      <section className="section-report">
        <div className="container">
          <div className="card-row">
            {state.selectedYear === "all"
              ? cardData.map((card) => (
                  <CardDownload
                    cardTitle={card.title}
                    link={card.link}
                    key={card.id}
                  />
                ))
              : cardData.map(
                  (card) =>
                    card.year === state.selectedYear && (
                      <CardDownload
                        cardTitle={card.title}
                        link={card.link}
                        key={card.id}
                      />
                    )
                )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default RightToInformation;
